import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Button from "../../components/linkbutton"
import { StaticImage } from "gatsby-plugin-image"
import "../../stylesheets/thanks.scss"

const ThanksPage = () => (
  <Layout>
    <SEO title="お問い合わせ受け付け完了" />
    <div className="thanksPage">
      <div className="thanksPage-inner">
        <div className="thanksPage-messageWrapper">
          <StaticImage
            src="../../images/complete.png"
            className="thanksPage-completeIcon"
          />
          <p className="thanksPage-messageTitle">
            お問い合わせを受け付けました。
          </p>
          <p className="thanksPage-message">
            内容の確認後、担当者よりご連絡させていただきます。
            <br />
            なお、お問い合わせ内容によっては、お答えできない場合や回答に時間を要する場合があります。
            <br />
            あらかじめご了承ください。
          </p>
        </div>
        <div className="thanksPage-buttonWrapper">
          <Button to="/" className="Button">
            トップページへ戻る
          </Button>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThanksPage
